<template>
	<div class="content-wrap">
		<!--机构经费报表-->
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="类型">
				<Select
					v-model="searchParams.type"
					:transfer="true"
					placeholder="请选择类型"
				>
					<Option :value="1">入账</Option>
					<Option :value="2">出账</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="入/出账时间">
				<DatePicker
					type="daterange"
					placeholder="请选择"
					:transfer="true"
					style="width: 100%"
					format="yyyy-MM-dd"
					:value="times"
					@on-change="v => (times = v)"
				></DatePicker>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="状态">
				<Select
					v-model="searchParams.approvedStatus"
					:transfer="true"
					placeholder="请选择"
				>
					<Option :value="-1">待审核</Option>
					<Option :value="1">审核通过</Option>
					<Option :value="0">审核不通过</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button @click="exportData">导出机构经费信息</Button>
		</CtmsAction>
		<div>
			<CtmsAction>
				<Tab currentName="first"/>
			</CtmsAction>
			<CtmsDataGrid
				:height="dataGridHeight"
				:columns="columns"
				:data="data"
				:page="page"
				ref="table"
			>
				<template slot-scope="{ row }" slot="information">
					<span>
						{{
							row.information
								? inAccountInfo.find(item => item.value === row.information).label
								: ""
						}}
					</span>
				</template>
			</CtmsDataGrid>
		</div>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			:departList="departList"
			:outlayId="updateId"
			:projectInfo="projectInfo"
			:inAccountInfo="inAccountInfo"
			:entry="entry"
			:type="fundsType"
			:apiUrlObj="apiUrlObj"
		></Add>
		<AddExamine
			:visible="addVisible2"
			@onCancel="addVisible2 = false"
			@updateData="initData"
			:departList="departList"
			:outlayId="updateId"
			:projectInfo="projectInfo"
			:inAccountInfo="inAccountInfo"
			:entry="entry"
			:type="fundsType"
			:apiUrlObj="apiUrlObj"
		></AddExamine>
	</div>
</template>

<script>
import Tab from "./components/InstitutionalFundingTab"
import fundsApi from "@/api/project/funds"
import Add from "@/views/Project/Funds/OrgFund/Add.vue"
import AddExamine from "@/views/Project/Funds/OrgFund/AddExamine.vue"
import config from "@/config/options.config"
import { mapState } from "vuex"

const { apiGetDeparts, apiGetReportFundsListPage, apiGetReportFundsDetail } =
	fundsApi
const { inAccountInfo } = config

export default {
	name: "OrgFundsReport",
	components: {
		Add,
		AddExamine,
		Tab
	},
	data() {
		return {
			loading: false,
			addVisible: false,
			addVisible2: false,
			entry: "",
			updateId: "",
			fundsType: "", // 1 入账 / 2 出账
			// 科室
			departList: [],
			// 项目基本信息
			projectInfo: {},
			inAccountInfo,
			apiUrlObj: {
				apiGetFundsDetail: apiGetReportFundsDetail
			},
			times: [],
			searchParams: {
				approvedStatus: "",
				type: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : ""
			},
			oldSearchParams: {
				approvedStatus: "",
				type: "",
				arrivalTimeStart: this.times ? this.times[0] : "",
				arrivalTimeEnd: this.times ? this.times[1] : ""
			},
			data: [],
			columns: [
				{
					title: "类型",
					key: "type",
					minWidth: 160,
					render: (h, params) =>
						h("span", {}, params.row.type === 1 ? "入账" : "出账")
				},
				{
					title: "状态",
					key: "approvedStatus",
					minWidth: 140,
					// render: (h, params) => h('span', {}, params.row.approvedStatus === null ? '待审核' : params.row.approvedStatus === 0 ? '审核不通过' : '审核通过'),
					render: (h, params) =>
						h(
							"span",
							{},
							params.row.type === 1
								? params.row.approvedStatus === null
									? "待审核"
									: params.row.approvedStatus === 0
										? "审核不通过"
										: "审核通过"
								: ""
						)
				},
				{
					title: "项目类别",
					key: "projectType",
					minWidth: 140
				},
				{
					title: "项目编号",
					key: "projectNo",
					minWidth: 130
				},
				{
					title: "项目名称",
					key: "projectName",
					minWidth: 120
				},
				{
					title: "项目负责科室",
					key: "department",
					minWidth: 140
				},
				{
					title: "项目负责人",
					key: "piName",
					minWidth: 140
				},
				{
					title: "手机号",
					key: "piMobile",
					minWidth: 130
				},
				{
					title: "申办方",
					key: "smo",
					minWidth: 120
				},
				{
					title: "CRO",
					key: "cro",
					minWidth: 120
				},
				{
					title: "入/出账金额(元)",
					key: "amount",
					minWidth: 140
				},
				{
					title: "入/出账时间",
					key: "arrivalTime",
					minWidth: 120
				},
				{
					title: "入/出账原因",
					key: "reason",
					minWidth: 120
				},
				{
					title: "扣税税费(元)",
					key: "tax",
					minWidth: 120
				},
				{
					title: "扣税税率",
					key: "taxRate",
					minWidth: 120
				},
				{
					title: "入账信息",
					key: "information",
					minWidth: 120,
					slot: "information"
				},
				{
					title: "其他经费用途",
					key: "other",
					minWidth: 120
				},
				{
					title: "开票公司名称",
					key: "invoiceCompany",
					minWidth: 120
				},
				{
					title: "纳税人识别码",
					key: "invoiceTaxNo",
					minWidth: 120
				},
				{
					title: "开票公司地址",
					key: "invoiceAddress",
					minWidth: 120
				},
				{
					title: "开票公司电话",
					key: "invoiceMobile",
					minWidth: 120
				},
				{
					title: "开户银行",
					key: "invoiceBank",
					minWidth: 120
				},
				{
					title: "开户行账号",
					key: "invoiceBankAccount",
					minWidth: 120
				},
				{
					title: "操作",
					key: "action",
					width: 120,
					fixed: "right",
					renderButton: ({ row }) => {
						const btnList = [
							{
								label: "查看",
								on: {
									click: this.handleViewShow
								}
							}
						]
						if (
							row.approvedStatus === null &&
							row.type === 1 &&
							this.systemActionPermissions.indexOf(
								"btn_project_outlay_mechanism_approve"
							) !== -1
						) {
							btnList.push({
								label: "审核",
								on: {
									click: this.handExamine
								}
							})
						}
						return btnList.filter(item => !!item)
					}
				}
			],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 190
		}),
		...mapState("permission", ["systemActionPermissions"])
	},
	mounted() {
		this.getPublicData()
		this.initData()
	},
	methods: {
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetReportFundsListPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize,
					classification: 1
				})
				if (res) {
					this.data = res.data.list || []
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 查看
		handleViewShow({ row }) {
			this.fundsType = row.type
			this.updateId = row.id
			this.entry = "view"
			this.projectInfo = {}
			this.addVisible = true
		},
		// 审核
		handExamine({ row }) {
			this.fundsType = row.type
			this.updateId = row.id
			this.entry = "view"
			this.projectInfo = {}
			this.addVisible2 = true
		},
		exportData() {
			/*
			 * classification 费用分类：1、机构 2、伦理
			 * type 费用类型：1、入账 2、出账
			 * arrivalTimeStart 入/出账时间-开始
			 * arrivalTimeEnd 入/出账时间-结束
			 * */
			const type = this.searchParams.type || ""
			const start = this.searchParams.arrivalTimeStart || ""
			const end = this.searchParams.arrivalTimeEnd || ""
			const approvedStatus =
				this.searchParams.approvedStatus === undefined
					? ""
					: this.searchParams.approvedStatus
			const url = `${this.$baseUrl}/report/outlay/export?classification=1&type=${type}&arrivalTimeStart=${start}&arrivalTimeEnd=${end}&approvedStatus=${approvedStatus}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 重置
		reset() {
			this.times = []
			this.searchParams.type = ""
			this.searchParams.approvedStatus = ""
			this.searchHandle()
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		},
		searchHandle() {
			this.searchParams.arrivalTimeStart = this.times.length
				? this.times[0]
				: ""
			this.searchParams.arrivalTimeEnd = this.times.length ? this.times[1] : ""
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
