<template>
  <div class="efficiency-statistics-tab" style="margin-left: -10px;">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="机构经费详情" name="first">机构经费详情</el-tab-pane>
      <!-- <el-tab-pane label="首笔款及累计到账" name="second">首笔款及累计到账</el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    currentName: {
      type: String,
      default: ''
    }
  },
  watch: {
    activeName: {
      handler() {
        this.activeName = this.currentName
      },
      immediate: true
    }
  },
  data() {
    return {
      activeName: ""
    }
  },
  methods: {
    handleClick(tab, event) {
      const { activeName } = this
      const basePath = "/report/institutional-funding/"
      if (activeName === "first") {
        this.$router.push({ path: `${basePath}details` })
      } else if (activeName === "second") {
        this.$router.push({ path: `${basePath}first-payment-received` })
      }
    },
  }
}
</script>

<style scoped>
.efficiency-statistics-tab /deep/ .el-tabs .el-tabs__nav{
  border: none;
}
.efficiency-statistics-tab /deep/ .el-tabs .el-tabs__item {
  height: 33px;
  line-height: 33px;
  width: 144px;
  font-size: 14px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border:1px solid #0a84ff;
  margin-right: -1px;
}
.efficiency-statistics-tab /deep/ .el-tabs .is-active,
.efficiency-statistics-tab /deep/ .el-tabs .el-tabs__item:hover{
  color: #fff;
  background: #2d8cf0;
}
</style>
