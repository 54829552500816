<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsAction>
			<Button
				@click="handleAddShow"
				v-if="!(systemActionPermissions.indexOf('btn_notice_add') === -1)"
				>新增公告
			</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Add
			:visible="addVisible"
			:typeList="typeList"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:updateId="updateId"
		></Add>
		<EditPreview
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			:typeList="typeList"
			:viewId="viewId"
		></EditPreview>
		<uploadFile
			:visible="uploadVisible"
			:uploadId="uploadId"
			@onCancel="uploadVisible = false"
		></uploadFile>
	</div>
</template>

<script>
import api from "@/api/notice/notice"
import { getEnumerateByKey } from "@/utils/util"
import EditPreview from "@/components/BusinessComponents/EditPreview/EditPreview.vue"
import { mapState } from "vuex"
import Moment from "moment"
import uploadFile from "./UploadFile.vue"
import Add from "./Add.vue"

const { apiGetPage, apiDelete, apiPublish } = api

export default {
	name: "index",
	components: {
		Add,
		EditPreview,
		uploadFile
	},
	data() {
		return {
			listData: [],
			columns: [
				{
					title: "标题",
					key: "title",
					minWidth: 300
				},
				{
					title: "发布时间",
					key: "publishDate",
					minWidth: 180,
					render: (h, params) =>
						h(
							"span",
							params.row.publishDate
								? Moment(params.row.publishDate).format("YYYY-MM-DD HH:mm:ss")
								: ""
						)
				},
				{
					title: "类型",
					key: "type",
					minWidth: 100,
					render: (h, params) => {
						const typeName = getEnumerateByKey(this.typeList, params.row.type)
						return h("span", {}, typeName ? typeName.v : "")
					}
				},
				{
					title: "状态",
					key: "publish",
					minWidth: 100,
					render: (h, params) =>
						h("span", {}, params.row.publish ? "已发布" : "未发布")
				}
			],
			actionColumn: {
				title: "操作",
				key: "action",
				width: 280,
				fixed: "right",
				renderButton: params => {
					const btnList = [
						{
							label: params.row.publish ? "取消发布" : "发布",
							on: {
								click: this.publishNotice
							},
							confirm: {
								title: `您确认${params.row.publish ? "取消发布" : "发布"}这条数据吗？`
							}
						},
						{
							label: "预览",
							on: {
								click: this.handleViewShow
							}
						},
						{
							label: "修改",
							on: {
								click: this.handleUpdateShow
							}
						},
						{
							label: "附件",
							on: {
								click: this.uploadAnnex
							}
						},
						{
							label: "删除",
							on: {
								click: this.handleSwitchState
							},
							confirm: {
								title: "您确认删除这条数据吗？"
							}
						}
					]
					if (this.systemActionPermissions.indexOf("btn_notice_add") === -1) {
						btnList[2] = null
						btnList[3] = null
						btnList[4] = null
					}
					return btnList.filter(item => !!item)
				}
			},
			loading: false,
			addVisible: false,
			viewVisible: false,
			uploadVisible: false,
			updateId: "",
			viewId: "",
			uploadId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	created() {
		this.initList()
		this.columns = [...this.columns, this.actionColumn]
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 50
		}),
		...mapState("permission", ["systemActionPermissions"]),
		typeList() {
			return this.$store.state.enumerate.NOTICE
		}
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 发布公告
		publishNotice({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiPublish({
					id: row.id
				})
				if (res) {
					this.initList()
				}
				this.loading = false
			})
		},
		// 上传附件
		uploadAnnex({ row }) {
			this.uploadId = row.id
			this.uploadVisible = true
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},

		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},

		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},

		// 禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 查看显示
		handleViewShow({ row }) {
			this.viewVisible = true
			this.viewId = row.id
		}
	},
	watch: {}
}
</script>
