<template>
	<Modal
		class="add-notice-drawer"
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="标题" prop="title">
						<Input v-model="formData.title" placeholder="请输入标题" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="类型" prop="type">
						<Select
							v-model="formData.type"
							:label-in-value="true"
							placeholder="请选择"
						>
							<Option
								:value="String(list.k)"
								v-for="list in typeList"
								:key="list.v"
								:label="list.v"
							></Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="是否置顶" prop="top">
						<Select
							v-model="formData.top"
							:label-in-value="true"
							placeholder="请选择"
						>
							<Option value="1" label="是"></Option>
							<Option value="0" label="否"></Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="内容" prop="content">
						<CtmsEditor
							ref="conTxt"
							:ctms_config="ctms_config"
							:clear="true"
							:height="'500px'"
						>
						</CtmsEditor>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/notice/notice"

const { apiGet, apiAdd, apiUpdate } = api

export default {
	name: "Add",
	props: ["visible", "typeList", "updateId"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "",
			rules: {
				title: { required: true, message: "标题不能为空", trigger: "blur" },
				type: {
					required: true,
					message: "类型不能为空",
					trigger: "blur change"
				},
				content: { required: true, message: "描述不能为空", trigger: "blur" },
				top: {
					required: true,
					message: "是否置顶不能为空",
					trigger: "blur change"
				}
			},
			ctms_config: {
				onchange: html => {
					this.formData.content = html
				},
				onblur: html => {
					this.formData.content = html
				}
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.$refs.conTxt.clearContent()
				this.title = "新增公告"
				if (this.updateId) {
					this.title = "修改公告"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.updateId })

						if (res) {
							this.formData = {
								...this.formData,
								...res.data
							}
							this.formData.type = String(this.formData.type)
							this.formData.top = String(this.formData.top)
							// 设置富文本内容
							this.$refs.conTxt.setContent(this.formData.content)
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.formData.content =
				this.$refs.conTxt.getContent() === "<p><br></p>"
					? ""
					: this.$refs.conTxt.getContent()
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							res = await apiUpdate({
								...this.formData,
								id: this.updateId
							})
						} else {
							res = await apiAdd({
								...this.formData
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style lang="less">
.add-notice-drawer {
	.drawer-footer {
		z-index: 2;
	}
}
</style>
