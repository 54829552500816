<template>
	<Modal
		:title="'上传附件'"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<CtmsUpload
			ref="upAttach"
			:headers="{ token: $store.state.user.token }"
			:action="`${$baseUrl}/common/notice/${uploadId}/files`"
			:upType="'attach'"
			:defaultFileList="defaultList"
			:uploadList="defaultList"
			@removeFile="removeFile"
			@updateList="getFileList"
		>
		</CtmsUpload>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭 </Button>
		</div>
	</Modal>
</template>
<script>
import api from "@/api/notice/notice"

const { apiGetFiles, apiDeleteFiles } = api
export default {
	props: ["visible", "uploadId"],
	data() {
		return {
			defaultList: []
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		removeFile(obj) {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiDeleteFiles({
					id: this.uploadId,
					fileId: obj.id
				})
				if (res) {
					this.$Message.success("删除成功!")
					this.getFileList()
				}
				this.loading = false
			})
		},
		getFileList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetFiles({ id: this.uploadId })
				if (res) {
					this.defaultList = res.data
				}
				this.loading = false
			})
		}
	},
	mounted() {},
	watch: {
		visible() {
			if (this.visible) {
				this.getFileList()
			}
		}
	}
}
</script>
